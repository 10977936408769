import styled from 'styled-components';
import { breakPoints, colors } from '@/styles';

export const Modal = styled.div`
  position: relative;
  background: #fff;
  color: #000;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem; /* Space between elements */
  z-index: 1;
  max-width: 600px;
  padding-top: 40px;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex: 0;
  }

  @media (max-width: ${breakPoints.mobile}) {
    position: fixed;
    border-radius: 0;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 80px 20px 0;
  }
`;

export const ModalClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  &:hover {
    stroke-width: 2px;
  }
`;

export const StickyContainer = styled.div`
  display: block;
  text-align: right;
  padding: 0px 10px !important;
  position: sticky;
  width: auto !important;
  right: 20px;
  bottom: 20px;

  @media (max-width: ${breakPoints.mobile}) {
    bottom: 10px;
    text-align: center;
  }
`;

export const StatusMessage = styled.div`
  color: ${colors.semantic.negative.dark};
`;

export const SignupForm = styled.div`
  width: 400px;
  display: flex;
  align-items: flex-end;
  gap: 10px;
  flex-direction: row;

  & > label {
    flex: 1;
  }

  @media (max-width: ${breakPoints.mobile}) {
    width: 100%;
    flex-direction: column;
    align-items: center;

    & > * {
      flex: 1;
      width: 100%;
    }
  }
`;
