'use client';
import { breakPoints } from '@/styles';
import styled from 'styled-components';

export const Container = styled.div<{ $breakpoint?: number }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  @media (max-width: ${props => props.$breakpoint || breakPoints.mobilePortrait}) {
    flex-direction: column;
  }
`;