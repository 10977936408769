'use client';
/* eslint-disable @next/next/no-img-element */
import { formatDate } from '@/lib/dateTime';
import Button from '@/ui/Button';
import { Card, Stack, Row } from '@/ui/primatives';
import { TruncatedContentText } from '@/components/Content/ContentBlock';
import { PostOrPage, PostsOrPages, Tags } from '@tryghost/content-api';
import Link from 'next/link';
import Input from '@/components/Input';
import { useState } from 'react';

const PastDives: React.FC<{
  papers: PostsOrPages;
  isCommunity?: boolean;
  title?: string;
  tagFlag?: boolean;
  tags?: Tags;
  currentTag?: string;
}> = ({ papers, isCommunity = false, title = 'Recent', tagFlag = false, tags, currentTag }) => {
  const [selectedTag, setSelectedTag] = useState<string>(currentTag || '');
  const handleTagChange = (selectedTag: string) => {
    setSelectedTag(selectedTag);
    window.location.href = `/blog${selectedTag ? `?tag=${selectedTag}` : ''}`;
  };

  const tagOptions = [
    { label: 'All', value: '' },
    ...(tags?.map((tag) => ({ label: tag.name, value: tag.slug })) || []),
  ];

  if (papers && papers.length < 1) {
    return !isCommunity ? (
      <Row gap={30}>
        {tagOptions.map((option) => (
          <a
            key={option.value}
            className={selectedTag === option.value ? 'link selected' : 'link'}
            onClick={(e) => {
              e.preventDefault();
              handleTagChange(option.value);
            }}
            href={`/community/${option.value}`}
            style={{
              textTransform: 'capitalize',
              fontWeight: selectedTag === option.value ? '600' : 'normal',
            }}
          >
            {option.label}
          </a>
        ))}
      </Row>
    ) : null;
  }

  return (
    <Stack gap={40}>
      {!isCommunity && (
        <Row gap={30}>
          {tagOptions.map((option) => (
            <a
              key={option.value}
              className={selectedTag === option.value ? 'link selected' : 'link'}
              onClick={(e) => {
                e.preventDefault();
                handleTagChange(option.value);
              }}
              href={`/community/${option.value}`}
              style={{
                textTransform: 'capitalize',
                fontWeight: selectedTag === option.value ? '600' : 'normal',
              }}
            >
              {option.label}
            </a>
          ))}
        </Row>
      )}
      {papers.map((paper: PostOrPage) => (
        <Link href={`/blog/${paper.slug}`} key={paper.slug}>
          <Card gap={10} style={{ padding: '0px' }}>
            <Row alignItems="stretch" justifyContent="stretch" gap={0} className="mobile-wrap">
              <Row
                style={{ width: '100%', maxWidth: '300px', height: 'auto' }}
                alignItems="center"
                className="hide-mobile"
              >
                <img
                  src={paper.feature_image ?? ''}
                  alt={paper.title}
                  style={{ width: '100%', maxWidth: '350px', height: 'auto', objectFit: 'cover' }}
                />
              </Row>
              <Stack gap={0} style={{ padding: '25px', width: '100%' }}>
                <div style={{ fontWeight: '400', fontSize: '25px' }}>{paper.title}</div>
                <div style={{ fontWeight: '250', wordSpacing: '3px', fontSize: '13px' }}>
                  <TruncatedContentText content={paper.plaintext} length={180} />
                </div>
                <Stack justifyContent="flex-end" gap={0} style={{ height: '100%' }}>
                  <div style={{ borderTop: '1px solid #ccc', margin: '10px 0' }}></div>
                  <Row gap={0} justifyContent="space-between" alignItems="center">
                    <Row gap={10} alignItems="center">
                      <img
                        src={paper.primary_author?.profile_image ?? ''}
                        alt={paper.primary_author?.name}
                        style={{ width: '30px', height: '30px', borderRadius: '50%' }}
                      />
                      <Stack gap={3}>
                        <div style={{ fontWeight: '200', fontSize: '12px' }}>{paper.primary_author?.name}</div>
                        <Row gap={0}>
                          <div style={{ fontWeight: '200', display: 'flex', fontSize: '12px' }}>
                            {formatDate(new Date(paper.published_at ?? ''))}
                            {tagFlag && paper.primary_tag?.name && (
                              <div
                                style={{
                                  textTransform: 'capitalize',
                                  paddingLeft: '4px',
                                }}
                              >
                                - {paper.primary_tag.name}
                              </div>
                            )}
                          </div>
                        </Row>
                      </Stack>
                    </Row>
                    {paper.reading_time && (
                      <div style={{ fontWeight: '200', fontSize: '12px', paddingLeft: '15px' }}>
                        {`${paper.reading_time} min read`}
                      </div>
                    )}
                  </Row>
                </Stack>
              </Stack>
            </Row>
          </Card>
        </Link>
      ))}
    </Stack>
  );
};

export default PastDives;
