'use client';
import React from 'react';
import * as Styled from './styled';
import Form from '@/components/Form';
import Button from '@/ui/Button';
import { useModal } from 'react-hooks-use-modal';
import { ROOT_LAYOUT_DOM_NODE_ID } from '@/config/appSettings';
import Input from '@/components/Input';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Confetti from '@/ui/Confetti';
import { TAddMemberResponse } from '@/ghost/lib/adminClient';
import tracking from '@/lib/tracking';
import * as Icon from '@/ui/Icon';

interface MailingListSignUpProps {
  onSignup: (email: string) => Promise<TAddMemberResponse>;
  isSubscribed?: boolean;
  currentEmail?: string;
  isFloating?: boolean;
}

const formSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Please enter a valid email address'),
});

export const MailingListSignUp: React.FC<MailingListSignUpProps> = ({
  onSignup,
  currentEmail,
  isSubscribed,
  isFloating,
}) => {
  const [Modal, open, close, isOpen] = useModal(ROOT_LAYOUT_DOM_NODE_ID, {
    preventScroll: true,
  });
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [statusMessage, setStatusMessage] = React.useState<string | null>(null);
  const [subscribed, setSubscribed] = React.useState(isSubscribed);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      email: currentEmail || '',
    },
  });

  const onSubmit = async (data: { email: string }) => {
    setIsSubmitting(true);
    setStatusMessage(null);
    const response = await onSignup(data.email);
    tracking.capture('subscribed_to_mailing_list');
    setSubscribed(response?.subscribed);

    if (response?.subscribed) {
      setTimeout(() => {
        close();
        setIsSubmitting(false);
      }, 2500);
    } else {
      setIsSubmitting(false);
      setStatusMessage(response?.message);
    }
  };

  const SignUpButton: React.FC<{}> = () => (
    <Button.Primary rounded disabled={subscribed} onClick={() => (isOpen ? close() : open())}>
      <Icon.User size="16" />
      {subscribed ? <span>Subscribed!</span> : <span>Subscribe</span>}
    </Button.Primary>
  );

  return (
    <>
      {isFloating ? (
        <Styled.StickyContainer>
          <SignUpButton />
        </Styled.StickyContainer>
      ) : (
        <SignUpButton />
      )}
      {isOpen && (
        <Modal>
          <Styled.Modal>
            <Styled.ModalClose>
              <Icon.X size="24px" onClick={close} />
            </Styled.ModalClose>
            <h4>Subscribe for the latest news, research, and updates from Oxen.ai</h4>
            <p>Join our mailing list and stay up to date with our latest content.</p>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Styled.SignupForm>
                <Input
                  disabled={isSubmitting}
                  style={{ flex: 1 }}
                  name="email"
                  placeholder="Email address"
                  submit={register}
                  error={errors.email}
                />
                <Button.Black style={{ height: 40 }} small type="submit" disabled={isSubmitting}>
                  Subscribe
                </Button.Black>
              </Styled.SignupForm>
              {statusMessage && <Styled.StatusMessage>{statusMessage}</Styled.StatusMessage>}
            </Form>
          </Styled.Modal>
          {subscribed && <Confetti density={500} zIndex={0} />}
        </Modal>
      )}
    </>
  );
};
