'use client';
import React from 'react';

import * as Styled from './styled';

export const ResponsiveFlexContainer: React.FC<{ children: React.ReactNode; breakpoint?: number }> = ({
  breakpoint,
  children,
}) => {
  return <Styled.Container $breakpoint={breakpoint}>{children}</Styled.Container>;
};
